





















import { RunStats, RunStatsRow } from '@/api';
import { formatDurationShort, formatFileSize } from '@/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';

type MetricKey = keyof Omit<RunStatsRow, 'timestamp'>;

type MetricEntry = { timestamp: string; x: number; y2: number; barWidth: number; value: number };

@Component
export default class Graph extends Vue {
  private readonly width = 100;

  private readonly height = 20;

  /** Pad the bars until this number is reached - this makes it look nicer when starting. */
  private readonly minimumBarCount = 60;

  @Prop({ required: true }) readonly data: RunStats | undefined;
  @Prop({ required: true }) readonly metrics!: MetricKey;
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly unit!: string;
  @Prop() readonly summary: string | undefined;

  private hoverInfo: MetricEntry | null = null;

  get lines(): MetricEntry[] | undefined {
    const values = this.data?.stats;
    if (typeof values === 'undefined') return undefined;
    // (1) determine y maximum - > maximum(values)
    const yMax = Math.max(...values.map((v) => v[this.metrics]));
    // (2) determine width per unit -> width / num(values)
    const widthPerSegment = this.width / Math.max(values.length, this.minimumBarCount);
    return values.map((entry, idx) => {
      const value = entry[this.metrics];
      return {
        timestamp: entry.timestamp,
        x: 0.5 * widthPerSegment + idx * widthPerSegment,
        y2: yMax > 0 ? this.height - (value / yMax) * this.height : this.height,
        barWidth: widthPerSegment * 0.9,
        value,
      };
    });
  }

  get summaryValue(): string | undefined {
    const values = this.data?.stats;
    if (this.hoverInfo) {
      let formattedDuration: string | undefined;
      if (typeof values !== 'undefined' && values.length > 0) {
        const firstTimestamp = new Date(values[0].timestamp).getTime();
        const currentTimestamp = new Date(this.hoverInfo.timestamp).getTime();
        const differenceInSeconds = Math.round((currentTimestamp - firstTimestamp) / 1000);
        formattedDuration = formatDurationShort(differenceInSeconds);
      }
      const valueWithUnit = this.formatNumberWithUnit(this.hoverInfo.value);
      return [valueWithUnit, formattedDuration].join(' @ ');
    }
    if (typeof values === 'undefined') {
      return undefined;
    } else if (this.summary === 'max') {
      const max = Math.max(...values.map((v) => v[this.metrics]), 0);
      return `Max. ${this.formatNumberWithUnit(max)}`;
    } else if (this.summary === 'sum') {
      const sum = values.map((v) => v[this.metrics]).reduce((prev, cur) => prev + cur, 0);
      return `∑ ${this.formatNumberWithUnit(sum)}`;
    } else {
      return undefined;
    }
  }

  hover(value: MetricEntry | null) {
    this.hoverInfo = value;
  }

  private formatNumberWithUnit(value: number): string {
    if (this.unit === 'bytes') {
      return formatFileSize(value);
    } else {
      const formattedNumber = value.toLocaleString(undefined, { maximumFractionDigits: 0 });
      return `${formattedNumber} ${this.unit}`;
    }
  }
}
