import { impersonatedUserIdKey } from './store/impersonation.module';

function buildDownloadUrl(url: string): string {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token in Local Storage');
  }
  // assume given URL is relative,
  // so must consider the current location
  const urlObj = new URL(url, window.location.toString());
  urlObj.searchParams.append('token', token);
  const impersonatedUserId = sessionStorage.getItem(impersonatedUserIdKey);
  if (typeof impersonatedUserId === 'string') {
    urlObj.searchParams.append('nodepit-impersonated-userid', impersonatedUserId);
  }
  return urlObj.toString();
}

export function downloadUrl(url: string): void {
  window.location.assign(buildDownloadUrl(url));
}

export async function downloadUrlToString(url: string): Promise<string> {
  const downloadUrl = buildDownloadUrl(url);
  const fetchResult = await fetch(downloadUrl);
  if (!fetchResult.ok) {
    throw new Error(`Fetching ${downloadUrl} failed with status ${fetchResult.status}`);
  }
  return await fetchResult.text();
}

export type DateAndTime = { date: string; time: string; combined: string };

export function formatDateTime(dateString: string | undefined, inline = false): DateAndTime | undefined {
  if (dateString == null) {
    return undefined;
  }

  function inlineCapitalization(str: string): string {
    return inline ? str.toLowerCase() : str;
  }

  function timezoneShiftedDate(d: Date): Date {
    return new Date(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().replace(/T.*/, ''));
  }

  function differenceInDays(d1: Date, d2: Date) {
    // nb: must adjust by timezone offset!
    // https://stackoverflow.com/a/29774197
    const d1Day = timezoneShiftedDate(d1);
    const d2Day = timezoneShiftedDate(d2);
    return (d1Day.getTime() - d2Day.getTime()) / (1000 * 60 * 60 * 24);
  }

  const dateInstance = new Date(dateString);
  const daysDifference = differenceInDays(dateInstance, new Date());
  let date;
  if (daysDifference === 0) {
    date = inlineCapitalization('Today');
  } else if (daysDifference === 1) {
    date = inlineCapitalization('Tomorrow');
  } else if (daysDifference === -1) {
    date = inlineCapitalization('Yesterday');
  } else {
    date = dateInstance.toISOString().replace(/T.*/, '');
  }

  const time = dateInstance.toLocaleTimeString('en', { timeStyle: 'short', hourCycle: 'h23' });

  return {
    date,
    time,
    combined: `${date}, ${time}`,
  };
}

export function toSeconds(date: Date | string): number {
  return Math.ceil(new Date(date).getTime() / 1000);
}

export function formatDuration(
  durationInSeconds: number,
  options?: {
    capitalize?: boolean;
    wordify?: boolean;
  }
): string {
  const weeks = Math.floor(durationInSeconds / (3600 * 24 * 7));
  const days = Math.floor((durationInSeconds % (3600 * 24 * 7)) / (3600 * 24));
  const hours = Math.floor((durationInSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const parts = [];
  if (weeks > 0) parts.push(`${weeks} week${weeks !== 1 ? 's' : ''}`);
  if (days > 0) parts.push(`${days % 7} day${days !== 1 ? 's' : ''}`);
  if (hours > 0) parts.push(`${hours % 24} hour${hours !== 1 ? 's' : ''}`);
  if (minutes > 0) parts.push(`${minutes % 60} minute${minutes !== 1 ? 's' : ''}`);
  if (seconds > 0) parts.push(`${seconds % 60} second${seconds !== 1 ? 's' : ''}`);

  let result = parts.join(' ') || '0 seconds';
  if (options?.wordify) {
    result = result.replace(/\d+/g, (n) => wordify(parseInt(n, 10)));
  }
  if (options?.capitalize) {
    result = result.replace(/\s[a-z]|^[a-z]/gi, (char) => char.toUpperCase());
  }
  return result;
}

export function formatDurationShort(durationInSeconds: number): string {
  const duration = formatDuration(durationInSeconds);
  return duration.replace(/(\d+) (\w)\w+/g, '$1$2');
}

export function wordify(num: number): string {
  if (num < 0) {
    return `minus ${wordify(-num)}`;
  }

  const units = [
    'zero', //
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
  ];

  const teens = [
    'ten', //
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];

  const tens = [
    'twenty', //
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  const thousands = [
    'thousand', //
    'million',
    'billion',
    'trillion',
  ];

  function numToWords(n: number): string {
    if (n < 10) {
      return units[n];
    }

    if (n < 20) {
      return teens[n - 10];
    }

    if (n < 100) {
      const tenPart = Math.floor(n / 10);
      const unitPart = n % 10;
      return tens[tenPart - 2] + (unitPart !== 0 ? `-${units[unitPart]}` : '');
    }

    if (n < 1000) {
      const hundredPart = Math.floor(n / 100);
      const rest = n % 100;
      return `${units[hundredPart]} hundred${rest !== 0 ? ` and ${numToWords(rest)}` : ''}`;
    }

    for (let i = 1; i < thousands.length; i++) {
      const divisor = Math.pow(1000, i);
      if (n < divisor * 1000) {
        const majorPart = Math.floor(n / divisor);
        const rest = n % divisor;
        return `${numToWords(majorPart)} ${thousands[i - 1]}${rest !== 0 ? ` ${numToWords(rest)}` : ''}`;
      }
    }

    return '';
  }

  return numToWords(num);
}

export function formatFileSize(size: number): string {
  // 1024 is scientifically correct, but most computers show filesize this way
  const divideBy = 1000;
  if (isNaN(size)) size = 0;
  if (size < divideBy) return `${size.toLocaleString('en', { maximumFractionDigits: 0 })} bytes`;
  size /= divideBy;
  if (size < divideBy) return `${size.toLocaleString('en', { maximumFractionDigits: 0 })} kB`;
  size /= divideBy;
  return `${size.toLocaleString('en', { maximumFractionDigits: 0 })} MB`;
}
