import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { Run, RunStats } from '../api';
import * as api from '../api';

export interface IRunState {
  items: Run[] | null;
  item: Run | null;
  log: string[];
  stats: RunStats | null;
  skip: number;
}

const state: IRunState = {
  items: null,
  item: null,
  log: [],
  stats: null,
  skip: 0,
};

const getters: GetterTree<IRunState, IRootState> = {
  items: (state: IRunState) => state.items,
  item: (state: IRunState) => state.item,
  log: (state: IRunState) => state.log,
  stats: (state: IRunState) => state.stats,
  skip: (state: IRunState) => state.skip,
};

const actions: ActionTree<IRunState, IRootState> = {
  loadItems: async (context: ActionContext<IRunState, IRootState>) => {
    const { runs } = await api.listRuns({ project: context.rootState.project.currentItem?._id });
    context.commit('setItems', runs);
  },
  loadItem: async (context: ActionContext<IRunState, IRootState>, id: string) => {
    const item = await api.getRun(id);
    context.commit('setItem', item);
    context.commit('setLog', []);
    context.commit('setSkip', 0);
  },
  updateItem: async (context: ActionContext<IRunState, IRootState>, id: string) => {
    const item = await api.getRun(id);
    context.commit('setItem', item);
  },
  loadLog: async (context: ActionContext<IRunState, IRootState>, id: string) => {
    const log = await api.getLog(id, { skip: context.state.skip });
    context.commit('setLog', log.logs);
    context.commit('setSkip', context.state.skip + log.chunks);
  },
  updateLog: async (context: ActionContext<IRunState, IRootState>, id: string) => {
    const log = await api.getLog(id, { skip: context.state.skip });
    context.commit('setLog', context.state.log.concat(log.logs));
    context.commit('setSkip', context.state.skip + log.chunks);
  },
  loadStats: async (context: ActionContext<IRunState, IRootState>, id: string) => {
    const stats = await api.getRunStats(id);
    context.commit('setStats', stats);
  },

  deleteItem: async (context: ActionContext<IRunState, IRootState>, id: string) => {
    await api.deleteRun(id);
    context.dispatch('loadItems');
  },
};

const mutations: MutationTree<IRunState> = {
  setItems(state: IRunState, items: Run[] | null) {
    state.items = items;
  },
  setItem(state: IRunState, item: any) {
    state.item = item;
  },
  setLog(state: IRunState, log: string[]) {
    state.log = log;
  },
  setSkip(state: IRunState, skip: number) {
    state.skip = skip;
  },
  setStats(state: IRunState, stats: RunStats) {
    state.stats = stats;
  },
};

const run: Module<IRunState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default run;
